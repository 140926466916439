'use client'

import { useEffect, useState } from 'react'
import styles from './IdentityCircle.module.css'
import ProfileBadge from '@crystal-eyes/meetings/components/profiles/ProfileBadge'
import DefaultImage from '@crystal-eyes/meetings/components/elements/Avatar/DefaultImage'
import classNamesBind from 'classnames/bind'

type Props = {
  r: number
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  includeVerified?: boolean
  isCrystalUser?: boolean
  photoUrl?: string | null
  fullName?: string | null
}

const cn = classNamesBind.bind(styles)

export default function IdentityCircle({
  r,
  className = '',
  style = {},
  onClick,
  onMouseOver,
  onMouseOut,
  includeVerified,
  fullName,
  isCrystalUser,
  photoUrl,
}: Props) {
  const [imageError, setImageError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [preserveAspectRatio, setPreserveAspectRatio] = useState<string>('none')
  const isVerified = includeVerified && isCrystalUser

  useEffect(() => {
    setLoading(false)
  }, [])

  const handleImageError = () => {
    setImageError(true)
  }

  const handleImageLoad = () => {
    if (photoUrl && !imageError) {
      setPreserveAspectRatio('xMidYMid slice')
    }
  }

  const getInitials = (fullName: string) => {
    const names = fullName.split(' ')
    let initials: string | null = null
    if (names.length >= 2) {
      initials = `${names[0]![0]}${names[1]![0]}`
    } else if (names.length >= 1) {
      initials = names[0]![0]
    }
    return initials
  }

  const pointerClass = onClick || onMouseOver ? 'tw-cursor-pointer' : ''

  return (
    <div
      className={cn('identityCircle', className)}
      style={isVerified ? { width: r * 2, height: r * 2 } : {}}
    >
      {!imageError && photoUrl ? (
        <svg
          className={`${styles.identityCircle} ${className}`}
          viewBox={`0 0 ${r * 2} ${r * 2}`}
          width={r * 2}
          height={r * 2}
          style={style}
        >
          {!loading && (
            <image
              className={cn('imageCircle', pointerClass)}
              href={photoUrl || ''}
              width={r * 2}
              height={r * 2}
              preserveAspectRatio={preserveAspectRatio}
              clipPathUnits="objectBoundingBox"
              onClick={onClick}
              onMouseOver={onMouseOver}
              onMouseOut={onMouseOut}
              onError={handleImageError}
              onLoad={handleImageLoad}
            />
          )}
        </svg>
      ) : fullName ? (
        <svg
          viewBox={`0 0 ${r * 2} ${r * 2}`}
          width={r * 2}
          height={r * 2}
          className={cn('tw-fill-personality')}
        >
          <circle cx={r} cy={r} r={r} />
          <text
            className={cn('tw-fill-white')}
            x="50%"
            y="50%"
            textAnchor="middle"
            dy=".3em"
            style={{ fontSize: `${r * 0.9}px` }}
          >
            {getInitials(fullName)}
          </text>
        </svg>
      ) : (
        <DefaultImage size={r * 2} />
      )}
      {isVerified && (
        <div style={{ position: 'absolute', top: '-0.2rem', right: '-1rem' }}>
          <ProfileBadge style={{ marginLeft: '-25%' }} type="verified" />
        </div>
      )}
    </div>
  )
}
